import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class UserGroups extends APIBaseFunctions {
  static UserGroupDataService = class {
    private static cachedUserGroupDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = UserGroups.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedUserGroupDataSliceObj }, dataSliceObj)
      if (sortingOrder.length > 0) {
        return http.get(`/usrgroups?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/usrgroups?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/usrgroups/${id}`)
    }

    static getCount () {
      return http.get('/usrgroups/count')
    }

    static create (data: any) {
      return http.post('/usrgroups', data)
    }

    static update (id: string, data: any) {
      return http.put(`/usrgroups/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/usrgroups/${id}`)
    }

    static deleteAll () {
      return http.delete('/usrgroups')
    }

    static findByUserGroupStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = UserGroups.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedUserGroupDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/usrgroups?brugergruppe_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }
  }

  static UserGroupRightsDataService = class {
    private static cachedUserGroupRightsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = UserGroups.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedUserGroupRightsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/usrgrouprights?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/usrgrouprights?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/usrgrouprights/${id}`)
    }

    static getCount () {
      return http.get('/usrgrouprights/count')
    }

    static create (data: any) {
      return http.post('/usrgrouprights', data)
    }

    static update (id: string, data: any) {
      return http.put(`/usrgrouprights/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/usrgrouprights/${id}`)
    }

    static deleteAll () {
      return http.delete('/usrgrouprights')
    }
  }

  /* static UserGroupRightsToUserGroupRelationDataService = class {
    static getAll (sortingOrder = '') {
      if (sortingOrder.length > 0) {
        return http.get(`/brugergrupperettighed-brugergruppes?_sort=${sortingOrder}`)
      }

      return http.get('/brugergrupperettighed-brugergruppes')
    }

    static get (id: string) {
      return http.get(`/brugergrupperettighed-brugergruppes/${id}`)
    }

    static getCount () {
      return http.get('/brugergrupperettighed-brugergruppes/count')
    }

    static create (data: any) {
      return http.post('/brugergrupperettighed-brugergruppes', data)
    }

    static update (id: string, data: any) {
      return http.put(`/brugergrupperettighed-brugergruppes/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/brugergrupperettighed-brugergruppes/${id}`)
    }

    static deleteAll () {
      return http.delete('/brugergrupperettighed-brugergruppes')
    }
  } */
}

/* declare namespace UserGroups {
  type UserGroupDataService = typeof UserGroups.UserGroupDataService.prototype
  type UserGroupRightsDataService = typeof UserGroups.UserGroupDataService.prototype
  type UserGroupRightsToUserGroupRelationDataService = typeof UserGroups.UserGroupDataService.prototype
} */

// export class UserGroupDataService { }

export default new UserGroups()
